import { ICON_MAPPING } from './icon-mapping';

/**
 * Maps icon name to path to icon. Is attached to `TUI_ICONS_PATH`.
 * It requires that icons were properly processed and are available under the specified path.
 * By default, we map material icon names directly to file name because they are the same.
 * But for the internal Taiga icons and some specific ones of us, we can use a mapping.
 *
 * @param name - Name of icon (for usage in Taiga UI)
 * @return - Path to icon
 */
export function iconsPath(name: string): string {
  // first, we look up a mapping
  const mapped = ICON_MAPPING[name];

  // for Taiga UI icons we use their icons if no mapping is provided
  if (name.startsWith('tui') && !mapped) {
    return `assets/icons/taiga-ui/${name}.svg#${name}`;
  }

  if (mapped) {
    // we found a mapping of Taiga UI -> so it is a Material icon
    if (name.startsWith('tui')) {
      return `assets/icons/material/${mapped}.svg#${mapped}`;
    }
    // we found a mapping of our icons
    if (name.startsWith('ion')) {
      return `assets/icons/ionity/${mapped}.svg#${mapped}`;
    }
  }

  // we map the remaining icons directly to Material icons
  name = mapped ?? name;
  return `assets/icons/material/${name}.svg#${name}`;
}
