import { TuiDay, TuiDayRange, TuiTime } from '@taiga-ui/cdk';
import { DateTime, Interval } from 'luxon';

// Todo: Clean up, split to separate files, add unit tests, group into DateHelper?, docs

export function isoDate(day: TuiDay | null, time: TuiTime | null): string {
  return day
    ? new Date(day.year, day.month, day.day, time?.hours ?? 0, time?.minutes ?? 0, time?.seconds ?? 0).toISOString()
    : '';
}

/**
 * Returns the current day as ISO 8601 **date** string **without time zone**.
 * @param timeZone Time zone where the current day should be determined. When not given, the system's time zone is used.
 */
export function currentDate(timeZone: string = getTimeZone()): string {
  const today = DateTime.local({ zone: timeZone }).startOf('day');
  return today.toISODate();
}

// Todo: Check if TuiDay.parseRawDateString or TuiDay.normalizeParse is not enough
/**
 * Transforms a string to a {@link TuiDay}.
 *
 * @param date - The date as string to transform
 */
export function toTuiDay(date: string): TuiDay {
  const parsed = DateTime.fromISO(date).toObject();
  if (parsed && Number.isInteger(parsed.year) && Number.isInteger(parsed.month) && Number.isInteger(parsed.day)) {
    return new TuiDay(parsed.year, parsed.month - 1, parsed.day);
  }
  throw new Error('Not possible to parse string to TuiDay: ' + date);
}

// Todo: Check if TuiTime.fromString(time) is not enough
/**
 * Transforms a string to a {@link TuiTime}.
 *
 * @param time - The time as string to transform
 */
export function toTuiTime(time: string): TuiTime {
  const parsed = DateTime.fromISO(time).toObject();
  if (
    parsed &&
    Number.isInteger(parsed.hour) &&
    Number.isInteger(parsed.minute) &&
    Number.isInteger(parsed.second) &&
    Number.isInteger(parsed.millisecond)
  ) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return new TuiTime(parsed.hour!, parsed.minute!, parsed.second!, parsed.millisecond!);
  }
  throw new Error('Not possible to parse string to TuiTime: ' + time);
}

export type QuickPick = [DateTime | null, DateTime | null];

export type QuickPickKey = 'TODAY' | 'YESTERDAY' | 'THIS_WEEK' | 'LAST_WEEK' | 'LAST_30_DAYS' | 'LAST_90_DAYS';

/**
 * Calculates a utility object containing some useful date intervals in respect to a given date (baseline).
 *
 * @param baseline - The date to calculate the intervals from, defaults to now()
 */
export function createQuickPicks(baseline?: DateTime): Record<QuickPickKey, QuickPick> {
  baseline = baseline ?? DateTime.now();
  return {
    TODAY: [baseline.startOf('day'), DateTime.now().endOf('day').set({ millisecond: 0 })],
    YESTERDAY: [
      baseline.startOf('day').minus({ day: 1 }),
      baseline.endOf('day').minus({ day: 1 }).set({ millisecond: 0 }),
    ],
    THIS_WEEK: [baseline.startOf('week'), DateTime.now().endOf('week').set({ millisecond: 0 })],
    LAST_WEEK: [
      baseline.startOf('week').minus({ week: 1 }),
      baseline.endOf('week').minus({ week: 1 }).set({ millisecond: 0 }),
    ],
    LAST_30_DAYS: [baseline.startOf('day').minus({ days: 30 }), baseline.endOf('day').set({ millisecond: 0 })],
    LAST_90_DAYS: [baseline.startOf('day').minus({ days: 90 }), baseline.endOf('day').set({ millisecond: 0 })],
  };
}

/**
 * Calculate an {@link Interval} from a {@link TuiDayRange} which start at begin of first day and ends on end of last day.
 *
 * @param range - The {@link TuiDayRange} to convert
 */
export function getFullIntervalFromTuiDayRange(range: TuiDayRange) {
  const from = DateTime.fromISO(range.from.toJSON());
  const to = DateTime.fromISO(range.to.toJSON());
  return Interval.fromDateTimes(from.startOf('day'), to.endOf('day'));
}

/**
 * Gets the name of the system's time zone.
 *
 * @returns the time zone name (e.g. America/Sao_Paulo)
 */
export function getTimeZone() {
  return DateTime.local().zoneName;
}

/**
 * Converts date from source time zone to target time zone
 * and returns the date in UTC representation.
 * By default the system's time zone is used as source time zone.
 *
 * @param dateTime the ISO date string e.g. (2023-11-22T03:00:00.000Z)
 * @param targetTimeZone the target time zone e.g. (Europe/Berlin)
 * @param sourceTimeZone the original time zone e.g. (America/Sao_Paulo), defaults to system timezone
 * @returns the date in target time zone e.g. (2023-11-21T23:00:00.000Z)
 */
export function convertDateTimeToTimeZone(
  dateTime: string,
  targetTimeZone: string,
  sourceTimeZone: string = getTimeZone(),
): string {
  if (dateTime) {
    const convertedDate = DateTime.fromISO(dateTime, { zone: sourceTimeZone })
      .setZone(targetTimeZone, { keepLocalTime: true })
      .toUTC();
    if (convertedDate.isValid) {
      return convertedDate.toString();
    }
    throw new Error(
      `Could not convert ${dateTime} from ${sourceTimeZone} to ${targetTimeZone}: ${convertedDate.invalidExplanation}`,
    );
  }
  return dateTime;
}
