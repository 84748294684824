import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ion-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class ErrorComponent {
  private activatedRoute = inject(ActivatedRoute);
  statusCode = this.activatedRoute.snapshot.data['statusCode'] as number;
}
