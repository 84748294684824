import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '@ion/env';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private authcService: AuthenticationService,
    private readonly router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.apiUrl)) {
      return from(this.authcService.getToken()).pipe(
        switchMap(token => {
          return next
            .handle(
              req.clone({
                headers: req.headers.set('Authorization', token),
              }),
            )
            .pipe(
              catchError((err: unknown) => {
                // handle authc related errors
                if (err instanceof HttpErrorResponse && err.status === 401) {
                  this.authcService.signOut();
                  this.router.navigate(['/sign-in']);
                  return EMPTY;
                }
                return throwError(() => err);
              }),
            );
        }),
      );
    } else {
      return next.handle(req);
    }
  }
}
