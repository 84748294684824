/**
 * Checks if a given feature key is included and not excluded based on provided feature lists.
 *
 * @param {string} key - The feature key to check.
 * @param {string[]} [excludedFeatures=[]] - An array of excluded feature keys.
 * @param {string[]|string} [includedFeatures='*'] - An array of included feature keys or a wildcard '*' to include all features.
 * @returns {boolean} Returns `true` if the feature is included and not excluded; otherwise, returns `false`.
 */
export function checkFeatures(key: string, excludedFeatures: string[] = [], includedFeatures: string[] | string = '*') {
  const included = includedFeatures === '*' || includedFeatures.includes(key);
  const excluded = excludedFeatures.includes(key);
  return included && !excluded;
}
