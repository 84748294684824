/**
 * All internal REST APIs deliver a payload for successful and erroneous calls.
 * In case or errors, each Ionity REST service produces a `Problem` in response.
 */
export interface ApiProblemResponse {
  /** The business ID of the problem */
  businessCode: string;
  /** A technical description of problem. It is a super-set of HTTP statuses.  */
  technicalCode: string;
  /** The HTTP status code associated with the problem.  */
  httpStatusCode: number;
  /** Time when Problem occurred  */
  timestamp: Date | string;
  /** The service that is the origin of the problem.  */
  originatingServiceName: string;
  /** Array containing invalid parameters which are provided in the request and failed the validation on server side  */
  invalidParams: InvalidParam[];
}

export interface InvalidParam {
  name: string;
  reason: string;
}

/**
 * We distinguish between three different kind of presentations in case of errors
 */
export enum SeverityType {
  /** Very important errors block the user from further interacting with the application */
  ALERT = 1,
  /** Show a notification which needs to be dismissed */
  NOTIFICATION_CONFIRM = 2,
  /** Show a notification (as error) which disappears automatically */
  NOTIFICATION = 3,
  /** Show a notification (as warning) which disappears automatically */
  WARNING = 4,
}

export interface ErrorInformation {
  title: string;
  message: string;
  severity: SeverityType;
}
