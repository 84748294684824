export const environment = {
  production: true,
  apiUrl: '{{apiUrl}}',
  confluenceBaseTemplateApiUrl: '{{confluenceBaseTemplateApiUrl}}',

  /**
   * Feature Toggling
   */
  featureTogglingMode: 'FEATURE_HUB',
  featureHub: {
    host: '{{featurehubUrl}}',
    apiKey: '{{featurehubToken}}',
    enableLogs: false,
    pollingFrequency: 15_000,
  },

  /**
   * IAM
   */
  ssoProviderName: 'AzureADIonity',
  enableAuthentication: true,
  amplifyConfig: {
    Auth: {
      region: 'eu-central-1',
      userPoolId: '{{userPoolId}}',
      userPoolWebClientId: '{{userPoolWebClientId}}',
      oauth: {
        domain: '{{userPoolDomain}}',
        redirectSignIn: `${window.location.origin}`,
        redirectSignOut: `${window.location.origin}`,
        responseType: 'code',
        scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      },
    },
  },

  /**
   * Map settings
   */
  mapApiKey: '{{mapApiKey}}',
  siteAvailability: {
    lowBoundary: '{{siteAvailabilityLowBoundary}}' as unknown as number,
    highBoundary: '{{siteAvailabilityHighBoundary}}' as unknown as number,
  },

  /**
   * Monitoring
   */
  maxLogsCount: '{{maxLogsCount}}' as unknown as number,

  /**
   * Development
   */
  oicpSimulatorApiUrl: '{{oicpSimulatorUrl}}',
  ocpiSimulatorApiUrl: '{{ocpiSimulatorUrl}}',
  logStateManagement: '{{logStateManagement}}' as unknown as boolean,
  includeDevArea: '{{includeDevArea}}' as unknown as boolean,

  /**
   * Site images settings
   */
  siteImageMaxSize: '{{siteImageMaxSize}}' as unknown as number,
  siteImageMinWidth: '{{siteImageMinWidth}}' as unknown as number,
  siteImageMinHeight: '{{siteImageMinHeight}}' as unknown as number,

  /**
   * Legacy feature flags -> Todo: remove once FeatureHub is fully applied
   */
  // The following feature flags will be replaced at runtime and then have type boolean
  // See: https://portal.ionity.eu/display/IONIT/Frontend+Feature+Flags for more information
  featureFlags: {
    stationConfiguration: '{{featureFlagStationConfiguration}}' as unknown as boolean,
    stationDiagnostics: '{{featureFlagStationDiagnostics}}' as unknown as boolean,
    stationOcppLogs: '{{featureFlagStationOcppLogs}}' as unknown as boolean,
    poiFlag: '{{featureFlagPoiFlag}}' as unknown as boolean,
    users: '{{featureFlagUsers}}' as unknown as boolean,
    pnc: '{{featureFlagPnc}}' as unknown as boolean,
    oicpRoaming: '{{featureFlagOicpRoaming}}' as unknown as boolean,
    chargingCurve: '{{featureFlagChargingCurve}}' as unknown as boolean,
    eClearingRoaming: '{{featureFlagEClearingRoaming}}' as unknown as boolean,
    gireveRoaming: '{{featureFlagGireveRoaming}}' as unknown as boolean,
    mobiERoaming: '{{featureFlagMobiERoaming}}' as unknown as boolean,
    ionityMobileAppRoaming: '{{featureFlagIonityMobileAppRoaming}}' as unknown as boolean,
    ionityNfcRoaming: '{{featureFlagIonityNfcRoaming}}' as unknown as boolean,
    ocpiLoadSimulatorRoaming: '{{featureFlagOcpiLoadSimulatorRoaming}}' as unknown as boolean,
    ionityMspRoaming: '{{featureFlagIonityMspRoaming}}' as unknown as boolean,
    cepsaRoaming: '{{featureFlagCepsaRoaming}}' as unknown as boolean,
    greenfluxRoaming: '{{featureFlagGreenfluxRoaming}}' as unknown as boolean,
    businessPartnerBrands: '{{featureFlagBusinessPartnerBrands}}' as unknown as boolean,
    products: '{{featureFlagProducts}}' as unknown as boolean,
    vat: '{{featureFlagVat}}' as unknown as boolean,
    darkMode: '{{featureFlagDarkMode}}' as unknown as boolean,
    redkitebase: '{{featureFlagRedKiteBase}}' as unknown as boolean,
    calibrationLaw: '{{featureFlagCalibrationLaw}}' as unknown as boolean,
    ocpp: '{{featureFlagOcpp}}' as unknown as boolean,
    stationFirmwareUpdate: '{{featureFlagStationFirmwareUpdate}}' as unknown as boolean,
    demoMode: '{{featureFlagDemoMode}}' as unknown as boolean,
    bulkOperationsPage: '{{featureFlagBulkOperationsPage}}' as unknown as boolean,
    resetComponent: '{{featureFlagResetComponent}}' as unknown as boolean,
    permissions: '{{featureFlagPermissions}}' as unknown as boolean,
    triggerMessage: '{{featureFlagTriggerMessage}}' as unknown as boolean,
    activateLightScene: '{{featureFlagActivateLightScene}}' as unknown as boolean,
    invoiceDetailRecords: '{{featureFlagInvoiceDetailRecords}}' as unknown as boolean,
    invoicingStatus: '{{featureFlagInvoicingStatus}}' as unknown as boolean,
    updateLightScene: '{{featureFlagUpdateLightScene}}' as unknown as boolean,
    authorizationChecks: '{{featureFlagAuthorizationChecks}}' as unknown as boolean,
    authenticationMedia: '{{featureFlagAuthenticationMedia}}' as unknown as boolean,
    authenticationMediaRoaming: '{{featureFlagAuthenticationMediaRoaming}}' as unknown as boolean,
    disableStationDelete: '{{featureFlagDisableStationDelete}}' as unknown as boolean,
    plausibilityMonitorPage: '{{featureFlagPlausibilityMonitorPage}}' as unknown as boolean,
    triggerAssetUpdate: '{{featureFlagTriggerAssetUpdate}}' as unknown as boolean,
    roamingLogs: '{{featureFlagRoamingLogs}}' as unknown as boolean,
    disconnectStation: '{{featureFlagDisconnectStation}}' as unknown as boolean,
    hardwareVersionField: '{{featureFlagHardwareVersionField}}' as unknown as boolean,
    chargeDetailRecords: '{{featureFlagChargeDetailRecords}}' as unknown as boolean,
    disabledSiteFields: '{{featureFlagDisabledSiteFields}}' as unknown as boolean,
    languageSwitcher: '{{featureFlagLanguageSwitcher}}' as unknown as boolean,
    defaultConnectorValues: '{{featureFlagDefaultConnectorValues}}' as unknown as boolean,
    bulkConfigurationCorrection: '{{featureFlagBulkConfigurationCorrection}}' as unknown as boolean,
    generateQrCode: '{{featureFlagGenerateQrCode}}' as unknown as boolean,
    sessionDetailLogs: '{{featureFlagSessionDetailLogs}}' as unknown as boolean,
    connectorStatusFetch: '{{featureFlagConnectorStatusFetch}}' as unknown as boolean,
    evDriversUserProfiles: '{{featureFlagEvDriversUserProfiles}}' as unknown as boolean,
    bulkOperationRetry: '{{featureFlagBulkOperationRetry}}' as unknown as boolean,
    withoutTypeE: '{{featureFlagWithoutTypeE}}' as unknown as boolean,
    multipleTariffElements: '{{featureFlagMultipleTariffElements}}' as unknown as boolean,
    pncLogs: '{{featureFlagPncLogs}}' as unknown as boolean,
    bulkOperationTemplates: '{{featureFlagBulkOperationTemplates}}' as unknown as boolean,
  },
};
