import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { inject, NgModule, NgZone } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { Amplify, Hub } from '@aws-amplify/core';
import { AuthenticationService } from './authentication.service';
import { SignInComponent } from './sign-in/sign-in.component';
import { environment } from '@ion/env';
import { AuthenticationInterceptor } from './authentication.interceptor';

@NgModule({
  imports: [RouterModule.forChild([{ path: 'sign-in', component: SignInComponent }])],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true }],
})
export class AuthModule {
  private readonly authcService = inject(AuthenticationService);
  private readonly zone = inject(NgZone);
  private readonly router = inject(Router);

  constructor() {
    // amplify-js internally uses shared message-hub to which we can subscribe,
    // but we have to do it before configure() is called
    Hub.listen('auth', data => {
      // there are many events but we only want this one
      if (data.payload.event === 'signIn_failure') {
        // save the error, so it can be shown later
        const lastAuthError = data.payload.message ?? '';
        this.authcService.setLastError(lastAuthError);

        // and cleanup the url, so it's not caught by 404 route
        location.hash = '';
      }
    });

    // now configure Amplify resources
    Amplify.configure(environment.amplifyConfig);
    Auth.configure(environment.amplifyConfig);
    Amplify.Logger.LOG_LEVEL = 'WARN';

    // establish navigation to returnUrl (original requested URL by user) which was set up in sign in method of authentication service
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'customOAuthState' && data !== '') {
        this.zone.run(() => {
          this.router.navigateByUrl(data, { replaceUrl: true });
        });
      }
    });
  }
}
