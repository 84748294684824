<div class="wrapper">
  <tui-island>
    <div class="text-center margin-bottom-lg">
      <img src="assets/images/ionity_logo_light.jpg" />
    </div>

    <button tuiButton appearance="primary" (click)="signIn()" autofocus>
      {{ "SIGN_IN.SIGN_IN_AZURE_AD" | translate }}
    </button>

    <tui-notification class="margin-top-md" status="error" *ngIf="authError">
      <label translate>SIGN_IN.COGNITO_ERROR</label>

      <div class="message">{{ authError }}</div>

      <div class="margin-top-sm">
        <a href="mailto:" translate>SIGN_IN.CONTACT_SUPPORT</a>
      </div>
    </tui-notification>
  </tui-island>
</div>
