import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TuiNotificationModule } from '@taiga-ui/core';
import { TuiButtonModule } from '@taiga-ui/core/components/button';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { ModalContext, ModalFooterComponent, ModalHeaderComponent } from '../modal';
import { AlertOptions } from './alert-options.model';

@Component({
  selector: 'ion-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [ModalHeaderComponent, TranslateModule, TuiNotificationModule, ModalFooterComponent, TuiButtonModule],
})
export class AlertComponent {
  readonly context = inject(POLYMORPHEUS_CONTEXT) as ModalContext<AlertOptions, void>;

  ok(): void {
    this.context.completeWith();
  }
}
