import { Injectable, OnDestroy } from '@angular/core';
import { filterNil } from '@ngneat/elf';
import {
  ClientContext,
  EdgeFeatureHubConfig,
  FeatureHubPollingClient,
  FeatureStateHolder,
  Readyness,
  fhLog,
} from 'featurehub-javascript-client-sdk';
import { fromEventPattern, map, Observable, of, Subject, switchMap, timeout } from 'rxjs';
import { first, startWith, takeUntil } from 'rxjs/operators';
import { FeatureHubServiceConfig, FeatureToggleService } from './feature-toggling.model';

/**
 * Service for working with FeatureHub (remote) feature toggles using the FeatureHub JavaScript client SDK.
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureHubService implements FeatureToggleService, OnDestroy {
  #fhConfig: EdgeFeatureHubConfig | null = null;
  #fhContext: ClientContext | null = null;

  private readonly destroy$ = new Subject<boolean>();

  constructor(private readonly config: FeatureHubServiceConfig) {
    console.log('You are using real FeatureHub service');
    if (config.enableLogs) {
      console.log('The provided config is:', config);
    }
    this.connect();
  }

  ngOnDestroy(): void {
    this.disconnect();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  isEnabled(key: string): boolean {
    return !!this.#fhConfig?.feature(key).enabled;
  }

  observe(key: string, negate: boolean = false): Observable<boolean> {
    return of(this.#fhConfig).pipe(
      filterNil(),
      first(),
      timeout(30_000),
      switchMap(config =>
        fromEventPattern<FeatureStateHolder<boolean>>(
          handler => config.feature(key).addListener(handler),
          (handler, token) => config.feature(key).removeListener(token),
        ).pipe(startWith(config.feature<boolean>(key))),
      ),
      map(state => {
        // state.flag could be undefined when the feature is unknown, or FH isn't ready yet
        if (state.flag === undefined && this.#fhConfig?.readyness === Readyness.Ready) {
          console.warn(`FeatureHub feature "${key}" is not defined`);
        }

        if (negate === true) {
          return !state.flag;
        } else {
          return !!state.flag;
        }
      }),
      takeUntil(this.destroy$),
    );
  }

  private connect(): void {
    this.disconnect();

    const { enableLogs, host, apiKey, pollingFrequency } = this.config;

    if (!enableLogs) {
      fhLog.quiet();
    }

    // create required config
    this.#fhConfig = new EdgeFeatureHubConfig(host, apiKey);

    // set up polling instead of SSE
    this.#fhConfig.edgeServiceProvider((repo, config) => new FeatureHubPollingClient(repo, config, pollingFrequency));

    this.#fhConfig
      .newContext()
      .build()
      .then(context => {
        this.#fhContext = context;
        if (enableLogs) {
          console.log('Feature Hub context created:', this.#fhContext);
        }
      })
      .catch(reason => {
        if (enableLogs) {
          console.warn('Feature Hub context creation failed:', reason);
        }
      });

    // "Readyness" indicates when the SDK has received state or failed to receive state.
    // You get two pieces of information, the readyness status and whether it's the first time it's been ready.
    // This is often the information you need to kick your UI into gear in some way.
    this.#fhConfig.addReadynessListener((readiness, firstTimeReady) => {
      if (firstTimeReady) {
        if (enableLogs) {
          console.log('Feature Hub connected and ready for first time!');
        }
      }
      if (enableLogs) {
        console.log('Feature Hub is:', readiness);
      }
    });
  }

  private disconnect() {
    this.#fhContext?.clear();
    this.#fhContext?.close();
    this.#fhConfig?.close();
    this.#fhContext = null;
    this.#fhConfig = null;
  }
}
