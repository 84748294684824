import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-web-apis/common';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { tuiPure, tuiZoneOptimized } from '@taiga-ui/cdk';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, EMPTY, fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DEFAULT_DESIGN_THEME, DesignTheme, ThemeSwitcher } from '../theme-switcher/theme-switcher.interface';

export interface WindowSize {
  height: number;
  width: number;
}

/**
 * A utility class to interact with user interface related thing (e.g. the side navigation).
 */
@Injectable({ providedIn: 'root' })
export class UiService implements ThemeSwitcher {
  #sideNav: BehaviorSubject<boolean>;
  readonly #resized: Observable<WindowSize> = EMPTY;

  static COLLAPSED_KEY = 'ionity.core.collapsed';
  static COLLAPSED_MODE_KEY = 'ionity.core.collapsed-mode';
  static SIDENAV_BREAKPOINT = 1000;

  readonly #themeStore = createStore(
    { name: 'theme' },
    withProps<{ name: DesignTheme }>({ name: DEFAULT_DESIGN_THEME }),
  );
  readonly #persist = persistState(this.#themeStore, {
    key: 'ionity.theme',
    storage: localStorageStrategy,
  });

  constructor(
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
    @Inject(WINDOW) private readonly window: Window,
    @Optional() private readonly zone?: NgZone,
  ) {
    const collapsed = localStorage.getItem(UiService.COLLAPSED_KEY) === '1';
    this.#sideNav = new BehaviorSubject(collapsed);

    if (zone) {
      // get an observable from the browser resize event
      // but run it out of the Angular zone to suppress change detection
      this.#resized = fromEvent(window, 'resize').pipe(
        tuiZoneOptimized(zone),
        debounceTime(200),
        map(
          () =>
            ({
              height: window.innerHeight,
              width: window.innerWidth,
            }) as WindowSize,
        ),
        distinctUntilChanged<WindowSize>(isEqual),
      );
    }

    // and now subscribe to it, to collapse side navigation
    // automatically when specific breakpoint is reached
    this.resized.subscribe(event => {
      if (this.extractMode() === 'auto') {
        if (event.width >= UiService.SIDENAV_BREAKPOINT) {
          this.closeSideNav(false);
        }
        if (event.width < UiService.SIDENAV_BREAKPOINT) {
          this.closeSideNav(true);
        }
      }
    });
  }

  /**
   * An observable of the state of the side navigation.
   */
  @tuiPure
  get sideNavCollapsed() {
    return this.#sideNav.asObservable();
  }

  /**
   * Angular optimized version of window resize event (as Observable, improved ChangeDetection).
   */
  get resized(): Observable<WindowSize> {
    return this.#resized;
  }

  /**
   * Current value of side navigation state (open/close).
   */
  get isSideNavCollapsed() {
    return this.#sideNav.value;
  }

  /**
   * Dispatch the window resize event manually.
   * This is helpful when dealing with NgxDatatable to trigger recalculation.
   */
  triggerWindowResize() {
    // we have to dispatch the event in the next event loop cycle
    // so that NgxDatatable is using the real current dimensions
    setTimeout(() => {
      // the following works only in modern browsers (which we merely support)
      this.window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  /**
   * Toggle the side navigation.
   */
  toggleSideNav() {
    this.closeSideNav(!this.#sideNav.value);
    // the first time the user manually switches, we are in manual mode
    // Todo: Find heuristic to switch back to "auto"
    this.localStorage.setItem(UiService.COLLAPSED_MODE_KEY, 'manual');
  }

  /**
   * Open or close the side navigation.
   */
  closeSideNav(close: boolean) {
    this.localStorage.setItem(UiService.COLLAPSED_KEY, close ? '1' : '0');
    this.#sideNav.next(close);
    this.triggerWindowResize();
  }

  /**
   * Open or close the mobile side navigation.
   */
  closeMobileSideNav(close: boolean) {
    this.#sideNav.next(close);
  }

  /**
   * Retrieve the value of a CSS variable within an element
   *
   * @param style - Style, for example `--color-primary`
   * @param element - Corresponding element
   */
  getValueOfStyle(style: string, element?: Element) {
    element = element ?? this.window.document.documentElement;
    return this.window.getComputedStyle(element).getPropertyValue(style);
  }

  /**
   * Select current theme from store (defaults to light).
   * Property will be persisted in local storage.
   */
  selectTheme(): Observable<DesignTheme> {
    return this.#themeStore.pipe(select(state => state.name));
  }

  /**
   * Switch to the provided theme. If not given, switch to opposite theme.
   * This is possible, because only two {@link DesignTheme} are currently implemented.
   *
   * @param theme - The theme to switch to
   */
  switchTheme(theme?: DesignTheme): void {
    this.#themeStore.update(state => {
      if (!theme) {
        if (state.name === 'light') {
          return { ...state, name: 'dark' };
        } else return { ...state, name: 'light' };
      }
      return { ...state, name: theme };
    });
  }

  private extractMode(): 'manual' | 'auto' {
    const mode = this.localStorage.getItem(UiService.COLLAPSED_MODE_KEY);
    return mode === 'manual' ? 'manual' : 'auto'; // auto is default
  }
}
