import { AbstractTuiValueTransformer, TuiDay, TuiTime } from '@taiga-ui/cdk';
import { convertDateTimeToTimeZone, getTimeZone, isoDate, toTuiDay, toTuiTime } from './date.fns';

export class DateTimeTransformer implements AbstractTuiValueTransformer<[TuiDay | null, TuiTime | null], string> {
  fromControlValue(controlValue: string): [TuiDay | null, TuiTime | null] {
    const day = controlValue ? toTuiDay(controlValue) : null;
    const time = controlValue ? toTuiTime(controlValue) : null;

    return day ? [day, time ? time : null] : [null, null];
  }

  toControlValue([day, time]: [TuiDay | null, TuiTime | null]): string {
    return isoDate(day, time);
  }
}

/**
 * Transforms between a TuiDay and a full ISO-8601 date string in UTC, at midnight current timezone.
 */
export class DateTransformer implements AbstractTuiValueTransformer<TuiDay | null, string> {
  fromControlValue(controlValue: string): TuiDay | null {
    return controlValue ? toTuiDay(controlValue) : null;
  }

  toControlValue(day: TuiDay | null): string {
    return isoDate(day, null);
  }
}

/**
 * Transforms between a TuiDay and a string in the format YYYY-MM-DD.
 */
export class DateOnlyTransformer implements AbstractTuiValueTransformer<TuiDay | null, string | null> {
  fromControlValue(controlValue: string | null): TuiDay | null {
    return controlValue ? TuiDay.jsonParse(controlValue) : null;
  }

  toControlValue(day: TuiDay | null): string | null {
    return day?.toJSON() ?? null;
  }
}

/**
 * Transforms between a TuiDay and a full ISO-8601 date string in UTC,
 * at midnight based on the given target timezone.
 * Target time zone defaults to UTC+1 if not provided.
 */
export class ZonedDateTransformer implements AbstractTuiValueTransformer<TuiDay | null, string> {
  readonly targetTimeZone: string = 'UTC+1';

  constructor(targetTimeZone?: string) {
    if (targetTimeZone !== undefined) {
      this.targetTimeZone = targetTimeZone;
    }
  }

  fromControlValue(controlValue: string): TuiDay | null {
    if (controlValue) {
      const convertedDate = convertDateTimeToTimeZone(controlValue, getTimeZone(), this.targetTimeZone);
      return toTuiDay(convertedDate);
    }
    return null;
  }

  toControlValue(day: TuiDay | null): string {
    const isoDateString = isoDate(day, null);
    if (isoDateString !== '') {
      return convertDateTimeToTimeZone(isoDateString, this.targetTimeZone, getTimeZone());
    }
    return isoDateString;
  }
}
