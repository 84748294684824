import { ModuleWithProviders } from '@angular/core';
import { environment } from '@ion/env';
import {
  ApiModule as AlertingApiModule,
  Configuration as AlertingConfiguration,
} from '@ionity/alerting-service-frontend-typings';
import {
  Configuration as AIApiConfiguration,
  ApiModule as AIApiModule,
} from '@ionity/asset-information-service-frontend-typings';
import {
  Configuration as AuditApiConfiguration,
  ApiModule as AuditApiModule,
} from '@ionity/audit-service-frontend-typings';
import {
  Configuration as AuthApiConfiguration,
  ApiModule as AuthApiModule,
} from '@ionity/auth-service-frontend-typings';
import { Configuration as BupaApiConfiguration, ApiModule as BupaApiModule } from '@ionity/bupa-frontend-typings';
import {
  Configuration as BupaV2ApiConfiguration,
  ApiModule as BupaV2ApiModule,
} from '@ionity/bupa-v2-frontend-typings';
import {
  ApiModule as CertificateHandlerApiModule,
  Configuration as CertificateHandlerConfiguration,
} from '@ionity/certificate-handler-frontend-typings';
import { ApiModule as CcApiModule, Configuration as CcConfiguration } from '@ionity/charging-curve-frontend-typings';
import {
  ApiModule as CsaApiModule,
  Configuration as CsaConfiguration,
} from '@ionity/charging-service-authorization-frontend-typings';
import {
  ApiModule as CsmApiModule,
  Configuration as CsmConfiguration,
} from '@ionity/charging-session-management-frontend-typings';
import { ApiModule, Configuration } from '@ionity/cockpit-bff-frontend-typings';
import {
  ApiModule as EvDriverApiModule,
  Configuration as EvDriverConfiguration,
} from '@ionity/ev-driver-frontend-typings';
import {
  ApiModule as LoggingApiModule,
  Configuration as LoggingConfiguration,
} from '@ionity/logging-service-frontend-typings';
import {
  Configuration as PoiApiConfiguration,
  ApiModule as PoiApiModule,
} from '@ionity/point-of-interest-frontend-typings';
import {
  ApiModule as PricingEvDriverApiModule,
  Configuration as PricingEvDriverConfiguration,
} from '@ionity/pricing-ev-driver-frontend-typings';
import { ApiModule as PricingApiModule, Configuration as PricingConfiguration } from '@ionity/pricing-frontend-typings';
import {
  Configuration as ProductsApiConfiguration,
  ApiModule as ProductsApiModule,
} from '@ionity/products-service-frontend-typings';
import {
  ApiModule as SrmApiModule,
  Configuration as SrmConfiguration,
} from '@ionity/service-record-mediation-frontend-typings';
import {
  ApiModule as SdpApiModule,
  Configuration as SdpConfiguration,
} from '@ionity/signeddata-provisioning-frontend-typings';
import {
  Configuration as SCGApiConfiguration,
  ApiModule as SCGApiModule,
} from '@ionity/station-communication-gateway-frontend-typings';
import { ApiModule as SOApiModule, Configuration as SOConfiguration } from '@ionity/station-operation-frontend-typings';
import {
  Configuration as SpaApiConfiguration,
  ApiModule as SpaApiModule,
} from '@ionity/station-protocol-adapter-frontend-typings';
import { ApiModule as QueueApiModule, Configuration as QueueConfiguration } from '@ionity/queue-frontend-typings';
import {
  Configuration as SprApiConfiguration,
  ApiModule as SprApiModule,
} from '@ionity/subscription-products-frontend-typings';

/**
 * An array of all required API configurations from typings packages.
 *
 * @type {ModuleWithProviders<unknown>[]}
 */
export const apiConfigs: ModuleWithProviders<unknown>[] = [
  AIApiModule.forRoot(() => new AIApiConfiguration({ basePath: `${environment.apiUrl}/asset-information-service` })),
  AlertingApiModule.forRoot(() => new AlertingConfiguration({ basePath: `${environment.apiUrl}/alerting-service` })),
  ApiModule.forRoot(() => new Configuration({ basePath: environment.apiUrl })),
  AuditApiModule.forRoot(() => new AuditApiConfiguration({ basePath: `${environment.apiUrl}/audit-service` })),
  AuthApiModule.forRoot(() => new AuthApiConfiguration({ basePath: `${environment.apiUrl}/auth-service` })),
  BupaApiModule.forRoot(
    () => new BupaApiConfiguration({ basePath: `${environment.apiUrl}/business-partner-management` }),
  ),
  BupaV2ApiModule.forRoot(
    () => new BupaV2ApiConfiguration({ basePath: `${environment.apiUrl}/business-partner-management-v2` }),
  ),
  CcApiModule.forRoot(() => new CcConfiguration({ basePath: `${environment.apiUrl}/charging-curve` })),
  CertificateHandlerApiModule.forRoot(
    () => new CertificateHandlerConfiguration({ basePath: `${environment.apiUrl}/certificate-handler` }),
  ),
  CsaApiModule.forRoot(
    () => new CsaConfiguration({ basePath: `${environment.apiUrl}/charging-service-authorization` }),
  ),
  CsmApiModule.forRoot(() => new CsmConfiguration({ basePath: `${environment.apiUrl}/charging-session-management` })),
  EvDriverApiModule.forRoot(() => new EvDriverConfiguration({ basePath: `${environment.apiUrl}/ev-driver` })),
  LoggingApiModule.forRoot(() => new LoggingConfiguration({ basePath: `${environment.apiUrl}/logging-service` })),
  PoiApiModule.forRoot(() => new PoiApiConfiguration({ basePath: `${environment.apiUrl}/point-of-interest` })),
  PricingApiModule.forRoot(() => new PricingConfiguration({ basePath: `${environment.apiUrl}/pricing-service` })),
  PricingEvDriverApiModule.forRoot(
    () => new PricingEvDriverConfiguration({ basePath: `${environment.apiUrl}/pricing-ev-driver-service` }),
  ),
  ProductsApiModule.forRoot(() => new ProductsApiConfiguration({ basePath: `${environment.apiUrl}/products-service` })),
  SCGApiModule.forRoot(
    () => new SCGApiConfiguration({ basePath: `${environment.apiUrl}/station-communication-gateway` }),
  ),
  SOApiModule.forRoot(() => new SOConfiguration({ basePath: `${environment.apiUrl}/station-operation` })),
  SdpApiModule.forRoot(() => new SdpConfiguration({ basePath: `${environment.apiUrl}/signeddata-provisioning` })),
  SpaApiModule.forRoot(() => new SpaApiConfiguration({ basePath: `${environment.apiUrl}/station-protocol-adapter` })),
  SrmApiModule.forRoot(() => new SrmConfiguration({ basePath: `${environment.apiUrl}/service-record-mediation` })),
  QueueApiModule.forRoot(() => new QueueConfiguration({ basePath: `${environment.apiUrl}/queue` })),
  SprApiModule.forRoot(() => new SprApiConfiguration({ basePath: `${environment.apiUrl}/subscription-products` })),
];
