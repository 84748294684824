import { Injectable } from '@angular/core';
import { AbstractTuiDialogService, TuiIdService } from '@taiga-ui/cdk';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ConfirmationModalOptions } from './confirmation-modal-options.model';
import { ConfirmationModalComponent } from './confirmation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService extends AbstractTuiDialogService<ConfirmationModalOptions> {
  readonly defaultOptions: ConfirmationModalOptions;
  readonly component = new PolymorpheusComponent(ConfirmationModalComponent);

  constructor(idService: TuiIdService) {
    super(idService);
    this.defaultOptions = {
      dismissible: true,
      title: '',
      heading: '',
      text: '',
      confirmButton: 'GENERAL.CONFIRM_CHANGES',
      cancelButton: 'GENERAL.CANCEL',
      params: {},
    };
  }
}
