import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const THEME_SWITCHER = new InjectionToken<ThemeSwitcher>('THEME_SWITCHER');

export type DesignTheme = 'light' | 'dark';

export const DEFAULT_DESIGN_THEME: DesignTheme = 'light';

export interface ThemeSwitcher<T = DesignTheme> {
  selectTheme: () => Observable<T>;
  switchTheme: (theme?: T) => void;
}
