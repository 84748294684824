// Todo: Remove this comments (put to docs?)
// modal reqs
//   x looks the same as design in figma
//   x it's not necesary to create new service for new modal type
//   x it's possible to use Component and to pass it custom params
//   x not possible to interact with the app when modal is open (backdrop has pointer-events: none)
//   - better typings

import { Injectable, Injector, Type } from '@angular/core';
import { AbstractTuiDialogService, TuiDialog, TuiIdService } from '@taiga-ui/cdk';
import { TuiDialogComponent, TuiDialogOptions } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Observable, endWith, ignoreElements } from 'rxjs';
import { AlertComponent, AlertOptions } from '../alert';
import { NoQuitPossibleDialogComponent } from '../no-quit-possible-dialog/no-quit-possible-dialog.component';

@Injectable({ providedIn: 'root' })
export class ModalService extends AbstractTuiDialogService<TuiDialogOptions<unknown>> {
  protected readonly defaultOptions: TuiDialogOptions<unknown> = {
    size: 'm',
    required: false,
    closeable: true,
    dismissible: false,
    label: '',
    header: '',
    data: undefined,
  } as const;
  protected readonly component = new PolymorpheusComponent(TuiDialogComponent);

  constructor(idService: TuiIdService) {
    super(idService);
  }

  openComponent<T, R>(Comp: Type<object>, options?: Partial<TuiDialogOptions<T>>, injector?: Injector): Observable<R> {
    if (injector) {
      return this.open(new PolymorpheusComponent(Comp, injector), options);
    }
    return this.open(new PolymorpheusComponent(Comp), options);
  }

  /**
   * Opens an informative dialog with a single "OK" button. Returned observable
   * emits and completes immediately after the dialog is acknowledged or closed.
   */
  openAlert(data: AlertOptions): Observable<void> {
    return this.openComponent(AlertComponent, { data, dismissible: false, size: 's' }).pipe(
      ignoreElements(),
      endWith(undefined),
    );
  }

  /**
   * Opens a dialog to confirm an abort of an "edit process".
   */
  openNoQuitPossibleDialog() {
    return this.openComponent(NoQuitPossibleDialogComponent, { dismissible: false });
  }
}

export type ModalContext<I, O> = TuiDialog<TuiDialogOptions<I>, O>;
