import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TuiButtonModule, TuiNotificationModule } from '@taiga-ui/core';
import { TuiIslandModule } from '@taiga-ui/kit';
import { AuthenticationService } from '../authentication.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  standalone: true,
  imports: [CommonModule, TuiIslandModule, TuiButtonModule, TuiNotificationModule, TranslateModule],
})
export class SignInComponent {
  authError: string;
  returnUrl: string | null = null;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) {
    this.authError = authenticationService.getLastError();
    this.returnUrl = this.route.snapshot.queryParams?.['returnUrl'];
  }

  async signIn() {
    return this.authenticationService.signIn(this.returnUrl ?? '');
  }
}
