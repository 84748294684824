import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule, EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { environment } from '@ion/env';
import { ErrorModule } from '@ion/error';
import { FeatureTogglingConfig, provideFeatureToggling } from '@ion/feature-toggling';
import { AuthModule } from '@ion/iam';
import { THEME_SWITCHER, UiService, ValidationErrors } from '@ion/misc';
import { CONFIRMATION_PROVIDER, MODAL_PROVIDER, ModalService } from '@ion/notifications';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  TuiAlertModule,
  TuiDialogModule,
  TuiRootModule,
  TuiSvgModule,
  tuiNotificationOptionsProvider,
  tuiSvgOptionsProvider,
} from '@taiga-ui/core';
import { TuiLanguageName, tuiLanguageSwitcher } from '@taiga-ui/i18n';
import {
  TUI_DATE_TIME_VALUE_TRANSFORMER,
  TUI_DATE_VALUE_TRANSFORMER,
  TUI_RADIO_DEFAULT_OPTIONS,
  TUI_RADIO_OPTIONS,
  TUI_VALIDATION_ERRORS,
} from '@taiga-ui/kit';
import { BindEventPlugin } from '@tinkoff/ng-event-plugins';
import { DateTimeTransformer, DateTransformer } from '~/core';
import { iconsPath } from '~/core/icons';
import { apiConfigs } from './api.config';
import { AppRoutes, createTranslateLoader } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      environment.enableAuthentication ? AuthModule : [],
      ErrorModule,
      TuiSvgModule,
      TuiAlertModule,
      BrowserModule,
      TuiRootModule,
      TuiDialogModule,
      ...apiConfigs,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
    ),
    tuiSvgOptionsProvider({
      path: iconsPath,
    }),
    tuiNotificationOptionsProvider({
      autoClose: 5000,
      icon: 'report_problem',
    }),
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: BindEventPlugin,
      multi: true,
    },
    ModalService,
    MODAL_PROVIDER,
    CONFIRMATION_PROVIDER,
    {
      provide: TUI_RADIO_OPTIONS,
      useValue: {
        ...TUI_RADIO_DEFAULT_OPTIONS,
        appearances: {
          unchecked: 'radio-unchecked',
          checked: 'radio-checked',
        },
      },
    },
    { provide: TUI_DATE_TIME_VALUE_TRANSFORMER, useClass: DateTimeTransformer },
    { provide: TUI_DATE_VALUE_TRANSFORMER, useClass: DateTransformer },
    {
      provide: THEME_SWITCHER,
      useExisting: UiService,
    },
    { provide: TUI_VALIDATION_ERRORS, useClass: ValidationErrors },
    // See: https://taiga-ui.dev/i18n/Dynamic_loader
    // See: https://github.com/Tinkoff/taiga-ui/issues/2991
    tuiLanguageSwitcher(async (language: TuiLanguageName): Promise<unknown> => {
      if (language === 'german') {
        return import('@taiga-ui/i18n/languages/german');
      }
      if (language === 'english') {
        return import('@taiga-ui/i18n/languages/english');
      }
      return new Error('No language specified for tuiLanguageSwitcher');
    }),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(AppRoutes),
    provideFeatureToggling(environment as FeatureTogglingConfig),
  ],
};
