import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TuiDialog } from '@taiga-ui/cdk';
import { TUI_DIALOGS_CLOSE, TuiButtonModule, TuiNotificationModule } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { Observable } from 'rxjs';
import { ConfirmationModalOptions } from './confirmation-modal-options.model';

@Component({
  selector: 'ion-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, TuiButtonModule, TuiNotificationModule, TranslateModule],
})
export class ConfirmationModalComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<ConfirmationModalOptions, boolean>,
    @Inject(TUI_DIALOGS_CLOSE) close$: Observable<unknown>,
  ) {
    close$.subscribe(() => {
      this.cancel();
    });
  }

  confirm(): void {
    this.context.completeWith(true);
  }

  cancel(): void {
    this.context.completeWith(false);
  }
}
