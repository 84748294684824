import { Injectable, OnDestroy } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Subject } from 'rxjs';
import { environment } from '@ion/env';

// 20 mins
const REFRESH_INTERVAL = 20 * 60 * 1000;

/**
 * Authentication service that provides methods for signing in, signing out,
 * and managing user authentication using AWS Cognito.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  /**
   * Interval for periodic token refresh.
   */
  interval = setInterval(() => this.refresh(), REFRESH_INTERVAL);

  /**
   * Emits when a token refresh was successful.
   */
  private tokenRefreshSuccess$ = new Subject<void>();

  /**
   * Last thrown error of AWS Cognito sign-in process, if there was any
   */
  lastAuthError = '';

  /**
   * Sign in a user using federated authentication.
   *
   * @param returnUrl - The return URL to redirect the user after authentication.
   * @returns A promise that resolves when the sign-in is successful.
   */
  async signIn(returnUrl: string) {
    return Auth.federatedSignIn({
      provider: environment.ssoProviderName as any,
      customState: returnUrl,
    });
  }

  /**
   * Check if the user is currently signed in.
   *
   * @returns A promise that resolves to true if the user is signed in, otherwise false.
   */
  async isSignedIn() {
    // we assume we are logged in when Cognito is disabled locally
    if (!environment.enableAuthentication) {
      return true;
    }
    try {
      // checks both user & session
      const session = await Auth.currentSession();
      return session.isValid();
    } catch (e) {
      // cognito throws if there is no user
      return false;
    }
  }

  /**
   * Get the current user's information.
   *
   * @returns A promise that resolves to an object with user ID and email.
   */
  async currentUserInfo(): Promise<{ id: string; email: string }> {
    // I think this is free-style object, configured in cognito, and it can differ for each OAuth provider,
    // but it seems to always contain id & attributes.email so that's what we will return here
    const userInfo = await Auth.currentUserInfo();

    return { id: userInfo.id, email: userInfo.attributes.email };
  }

  /**
   * Refresh the current session's tokens.
   */
  async refresh(): Promise<void> {
    try {
      const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
      const refreshToken = user.getSignInUserSession()?.getRefreshToken();

      // can be empty if cognito is not configured with responseType: "code"
      // in that case we can't do anything
      if (refreshToken) {
        return new Promise(resolve => user.refreshSession(refreshToken, resolve));
      }
    } catch (e) {
      // expected, cognito throws if we are not logged in
    }
  }

  /**
   * Sign out the currently authenticated user.
   */
  async signOut() {
    await Auth.signOut();
  }

  getLastError() {
    return this.lastAuthError;
  }

  setLastError(error: string) {
    this.lastAuthError = error;
  }

  /**
   * Get the authentication token for the current session.
   *
   * @returns The authentication token or an empty string if there is no valid session.
   */
  async getToken() {
    // cognito throws if there is not any session
    try {
      const session = await Auth.currentSession();
      return `Bearer ${session.getIdToken().getJwtToken()}`;
    } catch (e) {
      return '';
    }
  }

  /**
   * Returns an observable that emits when a token refresh was successful.
   */
  getTokenRefreshSuccess$() {
    return this.tokenRefreshSuccess$.asObservable();
  }

  /**
   * Get the current ID JWT token of the user.
   *
   * @returns The ID JWT token or null if there is none or the session is not valid.
   */
  async getIdToken(): Promise<string | null> {
    try {
      const session = await Auth.currentSession();
      if (session.isValid()) {
        return session.getIdToken().getJwtToken();
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  /**
   * Clean up resources when the component is destroyed.
   */
  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
