<ion-modal-header [title]="context.data.title"></ion-modal-header>

<section class="content">
  <tui-notification class="margin-y-md" [status]="context.data.type ?? 'error'">
    <p translate [translateParams]="context.data.params" class="font-bold">{{ context.data.heading }}</p>
    <p translate [translateParams]="context.data.params">{{ context.data.message }}</p>
  </tui-notification>
</section>

<ion-modal-footer>
  <button tuiButton size="m" (click)="ok()" appearance="primary"><span translate>GENERAL.OK</span></button>
</ion-modal-footer>
