import { DOCUMENT } from '@angular/common';
import { Component, Inject, Optional, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { THEME_SWITCHER, ThemeSwitcher } from '@ion/misc';
import { RouterOutlet } from '@angular/router';
import { TuiRootModule } from '@taiga-ui/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [TuiRootModule, RouterOutlet],
})
export class AppComponent {
  constructor(
    public translate: TranslateService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    @Optional() @Inject(THEME_SWITCHER) private readonly ui?: ThemeSwitcher,
  ) {
    // de-DE isn't currently used, but we kept it in this TranslateService configuration so it
    // can still easily be tested during local environment.
    translate.addLangs(['en-US', 'de-DE']);
    translate.setDefaultLang('en-US');
    translate.use('en-US').subscribe();

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|de/) ? browserLang : 'en');

    ui?.selectTheme().subscribe(theme => this.renderer.setAttribute(document.body, 'data-theme', theme));
  }
}
