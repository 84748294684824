import { Observable, of } from 'rxjs';
import { FeatureHubMockServiceConfig, FeatureToggleService } from '../feature-toggling.model';
import { checkFeatures } from './check-features.fn';

export class FeatureToggleMockService implements FeatureToggleService {
  constructor(private readonly config: FeatureHubMockServiceConfig) {
    if (config.enableLogs) {
      console.log('You are using custom mocked feature toggle service with config', config);
    }
  }

  isEnabled(key: string) {
    const { includedFeatures, excludedFeatures } = this.config;
    return checkFeatures(key, excludedFeatures, includedFeatures);
  }

  observe(key: string, negate: boolean = false): Observable<boolean> {
    const enabled = this.isEnabled(key);
    return of(negate ? !enabled : enabled);
  }
}

export class FeatureToggleMockAllEnabledService extends FeatureToggleMockService {
  constructor(enableLogs = false) {
    super({
      includedFeatures: '*',
      excludedFeatures: [],
      enableLogs,
    });
  }
}

export class FeatureToggleMockAllDisabledService extends FeatureToggleMockService {
  constructor(enableLogs = false) {
    super({
      includedFeatures: [],
      excludedFeatures: [],
      enableLogs,
    });
  }
}
