import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { snakeCase } from 'lodash-es';
import { concat, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Translates `camelCasedErrorKey` errors to `ERROR.UPPER_SNAKE_CASE` translations
 * Also, the result of your validator will be used for parameters replacement so if you
 * return `{ min: 10 }` you can then access that data in the translation using `{{min}}`
 *
 * For now, it's not globally available so you need to provide this at the component level.
 */
@Injectable()
export class ValidationErrors {
  // instance prop so the zone.js does not hit proxy during testing
  // otherwise tests won't pass (this is probably some TestBed internal thing because it works fine in browser)
  __zone_symbol__unconfigurables = {};

  constructor(translate: TranslateService) {
    // defines proxy in the object prototype so it's only invoked (once) for unknown keys
    Object.setPrototypeOf(
      this,
      new Proxy(Object.getPrototypeOf(this), {
        get: (_target: any, prop: string, receiver: any) => {
          const key = `ERROR.${snakeCase(prop).toUpperCase()}`;
          const newTranslator = () => (params: any) => translate.instant(key, params);

          // stream of DIFFERENT translate functions whenever the lang changes
          // this is needed because otherwise taiga will not re-render the error component
          // note that key computation is still done just once
          const value = concat(of(newTranslator()), translate.onLangChange.pipe(map(newTranslator)));

          // cache in the receiver so it never hits this proxy again
          Object.defineProperty(receiver, prop, { value });

          return value;
        },
      }),
    );
  }
}
