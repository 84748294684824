<div class="header">
  <h1 class="text-md">{{ context.title | translate: context.params }}</h1>
  <button tuiIconButton icon="close" appearance="flat" size="m" (click)="cancel()"></button>
</div>
<section class="content">
  <tui-notification class="margin-y-md" status="warning">
    <p class="font-bold">{{ context.heading | translate: context.params }}</p>
    <p>{{ context.text | translate: context.params }}</p>
  </tui-notification>
</section>
<div class="buttons">
  <button
    *ngIf="context.cancelButton"
    tuiButton
    size="s"
    appearance="outline"
    ion-id="confirmation-cancel"
    (click)="cancel()"
  >
    {{ context.cancelButton | translate }}
  </button>
  <button tuiButton size="s" ion-id="confirmation-confirm" (click)="confirm()">
    {{ context.confirmButton | translate }}
  </button>
</div>
