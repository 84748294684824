// We want to use material icon names by default
// If you need some other icon, you can add a mapping here
// (please do not create aliases for material icons here)
export const ICON_MAPPING: Record<string, string> = {
  // Taiga UI - Overridden by Material Icons
  // ***********
  tuiIconAlertCircleLarge: 'error_outline',
  tuiIconArrowDown: 'arrow_downward',
  tuiIconArrowLeft: 'arrow_back',
  tuiIconArrowRight: 'arrow_forward',
  tuiIconArrowUp: 'arrow_upward',
  tuiIconAttention: 'warning',
  tuiIconCalendar: 'calendar_today',
  tuiIconCalendarLarge: 'calendar_today',
  tuiIconCancel: 'cancel',
  tuiIconCheckCircleLarge: 'check_circle',
  tuiIconCheckLarge: 'done',
  tuiIconChevronDown: 'expand_more',
  tuiIconChevronDownLarge: 'expand_more',
  tuiIconChevronLeft: 'chevron_left',
  tuiIconChevronLeftLarge: 'chevron_left',
  tuiIconChevronRight: 'chevron_right',
  tuiIconChevronRightLarge: 'chevron_right',
  tuiIconChevronUp: 'expand_less',
  tuiIconClose: 'close',
  tuiIconCloseLarge: 'close',
  tuiIconClock: 'schedule',
  tuiIconClockLarge: 'schedule',
  tuiIconFilter: 'filter_list',
  tuiIconHideLarge: 'visibility_off',
  tuiIconHelpCircle: 'help_outline',
  tuiIconInfo: 'info',
  tuiIconMinusLarge: 'remove',
  tuiIconMoreVer: 'more_vert',
  tuiIconPlus: 'add',
  tuiIconRemoveLarge: 'highlight_off',
  tuiIconSearch: 'search',
  tuiIconSearchLarge: 'search',
  tuiIconShowLarge: 'visibility',
  tuiIconTime: 'schedule',
  tuiIconTimeLarge: 'schedule',
  tuiIconToggleOff: 'radio_button_unchecked',
  tuiIconToggleOffLarge: 'radio_button_unchecked',
  tuiIconTooltip: 'help_outline',
  tuiIconTooltipLarge: 'help_outline',
  tuiIconTrashLarge: 'delete',
  tuiIconWarningLarge: 'priority_high',

  // Custom
  // ***********
  ionAcType2: 'ac_type_2',
  ionAcTypeE: 'ac_type_e',
  ionCcsType2: 'ccs_type_2',
  ionChademo: 'chademo',
  ionFullAvailabilityIcon: 'full_availability_icon',
  ionHighAvailabilityIcon: 'high_availability_icon',
  ionLimitedAvailabilityIcon: 'limited_availability_icon',
  ionMinimumAvailabilityIcon: 'minimum_availability_icon',
  ionUnderConstructionIcon: 'under_construction_icon',
  ionInbound: 'inbound',
  ionOutbound: 'outbound',
};
