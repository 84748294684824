import { Observable } from 'rxjs';

/**
 * Abstract service for feature toggles that provides methods for checking and observing feature states.
 * It is mainly implemented as an abstract class (not an interface) to be able to use it with dependency injection.
 */
export abstract class FeatureToggleService {
  /**
   * Checks if a feature with the specified key is enabled.
   *
   * @param {string} key - The key of the feature to check.
   * @returns {boolean} Returns `true` if the feature is enabled; otherwise, returns `false`.
   */
  abstract isEnabled(key: string): boolean;

  /**
   * Observes the state of a feature with the specified key using an Observable.
   *
   * @param {string} key - The key of the feature to observe.
   * @param negate - Indicates if result should be negated.
   * @returns {Observable<boolean>} An observable that emits `true` when the feature is enabled and `false` when it is disabled.
   */
  abstract observe(key: string, negate: boolean): Observable<boolean>;
}

export class FeatureHubMockServiceConfig {
  excludedFeatures: string[] = [];
  includedFeatures: string[] | string = '*';
  enableLogs = false;
}

export interface FeatureTogglingConfig {
  featureTogglingMode: 'FEATURE_HUB' | 'ALL_ENABLED' | 'ALL_DISABLED' | 'CUSTOM';
  featureHub: FeatureHubServiceConfig;
  mockFeatures?: FeatureHubMockServiceConfig;
}

export class FeatureHubServiceConfig {
  host!: string;
  apiKey!: string;
  enableLogs!: boolean;
  pollingFrequency!: number;
}
