<ion-modal-header [title]="'GENERAL.OPEN_TASKS_HINT' | translate"></ion-modal-header>
<p class="info-box text-sm" translate>GENERAL.OPEN_TASKS_DETAILS</p>
<ion-modal-footer>
  <button tuiButton type="button" size="m" appearance="outline" (click)="context.$implicit.complete()">
    <span translate>GENERAL.CANCEL</span>
  </button>
  <button tuiButton type="button" size="m" (click)="context.$implicit.next(true)">
    <span translate>GENERAL.ABORT</span>
  </button>
</ion-modal-footer>
