import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

/**
 * The authenticationGuard checks if the user is authenticated.
 * If the user isn't authenticated, a redirect will happen to the sign in page.
 */
export const authenticationGuard = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Promise<boolean> => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  if (await authService.isSignedIn()) {
    return true;
  }

  router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
  return false;
};
