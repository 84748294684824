import { Provider } from '@angular/core';
import { FeatureHubService } from './feature-hub.service';
import { FeatureTogglingConfig } from './feature-toggling.model';
import {
  FeatureToggleMockAllDisabledService,
  FeatureToggleMockAllEnabledService,
  FeatureToggleMockService,
} from './testing/feature-toggle-mock.service';

export function provideFeatureToggling(config: FeatureTogglingConfig): Provider[] {
  switch (config.featureTogglingMode) {
    case 'FEATURE_HUB':
      return [
        {
          provide: FeatureHubService,
          useFactory: () =>
            new FeatureHubService({
              ...config.featureHub,
            }),
        },
      ];
    case 'ALL_ENABLED':
      return [
        {
          provide: FeatureHubService,
          useValue: new FeatureToggleMockAllEnabledService(config.mockFeatures?.enableLogs),
        },
      ];
    case 'ALL_DISABLED':
      return [
        {
          provide: FeatureHubService,
          useValue: new FeatureToggleMockAllDisabledService(config.mockFeatures?.enableLogs),
        },
      ];
    case 'CUSTOM':
      return [
        {
          provide: FeatureHubService,
          useFactory: () => {
            if (!config.mockFeatures) {
              throw new Error('No feature hub mock config provided');
            }
            return new FeatureToggleMockService(config.mockFeatures);
          },
        },
      ];
    default:
      return [
        {
          provide: FeatureHubService,
          useValue: new FeatureToggleMockAllDisabledService(config.mockFeatures?.enableLogs),
        },
      ];
  }
}
