import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TuiButtonModule } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { ModalContext, ModalFooterComponent, ModalHeaderComponent } from '../modal';

@Component({
  selector: 'ion-no-quit-possible-dialog',
  standalone: true,
  imports: [CommonModule, ModalHeaderComponent, ModalFooterComponent, TranslateModule, TuiButtonModule],
  templateUrl: './no-quit-possible-dialog.component.html',
  styleUrls: ['./no-quit-possible-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoQuitPossibleDialogComponent {
  constructor(@Inject(POLYMORPHEUS_CONTEXT) public readonly context: ModalContext<void, boolean>) {}
}
