import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@ion/env';
import { NotificationHelper } from '@ion/notifications';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { isPlainObject } from 'lodash-es';

const i18nKeysForServerErrorStatusCodes: { [statusCode in number]: string } = {
  501: 'ERROR.SERVICE_NOT_IMPLEMENTED',
  503: 'ERROR.SERVICE_UNAVAILABLE',
  504: 'ERROR.SERVICE_TIMEOUT',
};

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly helper: NotificationHelper) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith(environment.apiUrl)) {
      return next.handle(req).pipe(
        catchError((err: unknown) => {
          if (err instanceof ErrorEvent) {
            // we are probably offline
            // Todo: maybe we could (in future) show a notification and setup
            // Todo: some interval and retry the request when we're back online
            // Todo: is it really possible to get an ErrorEvent here?
            this.helper.handleError(err as unknown as HttpErrorResponse);
            return EMPTY;
          } else if (err instanceof HttpErrorResponse) {
            // Hint: Authentication related errors are handled in AuthenticationInterceptor
            // handle authorization related errors
            if ([403].includes(err.status)) {
              this.helper.handleError(err);
              return EMPTY;
            }

            // handle server errors
            if ([500, 501, 503, 504].includes(err.status)) {
              // in case API Gateway is down or times out, ingress nginx provides 5XX with body as html, therefore we need to check if error is an object in first place
              if (err.error && isPlainObject(err.error) && 'businessCode' in err.error) {
                // we are getting a business error with status code 5XX
                // so, let client code decide what to do
                return throwError(() => err);
              }

              err.status in i18nKeysForServerErrorStatusCodes
                ? this.helper.error(i18nKeysForServerErrorStatusCodes[err.status])
                : this.helper.handleError(err);

              return EMPTY;
            }
          }

          // everything else is considered to be expected and should be covered by client
          return throwError(() => err);
        }),
      );
    } else {
      return next.handle(req);
    }
  }
}
